



















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import { articleTypes } from "@/libs/appconst";
import qs from 'qs';

@Component
export default class TerminalList extends Vue {
  pagerHeader: any = {
    title: "终端管理",
    desc: "终端管理",
    breadcrumb: ["设备管理", "终端管理"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    status: null,
    keywords: "",
    sortDirection: null,
    sortField: null
  };
  multipleSelection: any[] = [];
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
  //   @Watch("dateRange")
  //   dateRangeChnage(value: Date[]) {
  //     if (value != null && value.length == 2) {
  //       this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
  //       this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
  //     } else {
  //       this.filter.startDate = "";
  //       this.filter.endDate = "";
  //     }
  //   }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  //   在列表选择设备 否
  handleSelectionChange(val: any[]) {
    console.log(val);

    this.multipleSelection = val;
  }
  // 删除终端
  async deleteTerminals() {
    if (this.multipleSelection.length == 0) {
      return void this.$message.error("请选择终端");
    } else {
      var result = await this.$confirm(`请再次确认是否删除终端`);
      if (result == "confirm") {
        this.loading = true;
          var deviceIds: any[] = [];
        this.multipleSelection.forEach(item => {
          deviceIds.push(item.id);
        });
        try {
          let res = await this.$ajax.delete('/api/services/app/Terminal/Delete?'+qs.stringify({ids:deviceIds},{indices:false})); //delete 需要序列化数组 再url上传值

          if (res.data.success) {
            this.$message.success("删除成功");
            this.getData();
          }
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      } else if (result == "cancel") {
        return;
      }
    }
  }
  async getData() {
    this.loading = true;
    try {
      var url = "/api/services/app/Terminal/GetPagedList";
      let res = await this.$ajax.get(url, {
        params: {
          ...this.filter
        }
      });

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  // activated() {
  //   if (this.$route.query.id) {
  //     this.filter.keywords = this.$route.query.id;
  //   }
  //   this.getData();
  // }
  created() {
    if (this.$route.query.id) {
      this.filter.keywords = this.$route.query.id;
    }
    this.filter.size = this.systemSettings.pageSize;
    this.getData();
  }
  getTypeDesc(type: number) {
    return articleTypes[type];
  }
  get statusOptions() {
    return this.getEnum("ChargingBatteryTerminalStatus");
  }
  memberItems: any[] = [];
  memberSearchLoading: boolean = false;
  async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
  showAuthDialog(id: string) {
    (this.$refs.auditDialog as any).show(id);
  }

  async takeSlotNo(slotNo: number, id: string) {
    var result = await this.$confirm(`确认弹出${slotNo}卡位吗`);
    if (result == "confirm") {
      var loading = this.$loading({
        text: "弹出中..."
      });
      try {
        var res = await this.$ajax.post(
          "/api/services/app/ChargingBattery/Eject",
          {
            slot: slotNo,
            id: id
          }
        );
        if (res.data.success) {
          this.$message.success("弹出成功");
          this.getData();
        }
      } catch (error) {}
      loading.close();
    }
  }
  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.getData();
  }
}
